export const loginUrls = ['login', 'studio/login', 'editor/login', 'editor/embed/login'];

export const blackList = [
  '404',
  'error',
  'signup',
  'recovery',
  'user/error',
  'editor/signup',
  'studio/signup',
  'editor/embed/signup',
  ...loginUrls
];
