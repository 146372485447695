import Logo, { LogoProps } from '@anm/components/Logo';
import { FC } from 'react';

import vtConfig from '../../config';

type AnimatronLogoProps = Omit<LogoProps, 'studioUrl' | 'waveUrl'>;

const { wave: waveUrl, studio: studioUrl } = vtConfig().urls;

const AnimatronLogo: FC<AnimatronLogoProps> = ({ variant, className }) => (
  <Logo {...{ variant, waveUrl, studioUrl, className }} />
);

export default AnimatronLogo;
