import TitleBlock from '@anm/components/TitleBlock';
import { FC } from 'react';

import NotificationBlockWrapper, {
  NotificationButton,
  NotificationIcon
} from './Wrapper';

export interface NotificationBlockProps {
  title: string;
  description: string;
  icon?: string;
  buttonTitle?: string;
  handleClick?: () => void;
}

const NotificationBlock: FC<NotificationBlockProps> = ({
  icon,
  title,
  description,
  buttonTitle,
  handleClick
}) => (
    <NotificationBlockWrapper>
      {icon && <NotificationIcon src={icon} />}

      <TitleBlock size="medium" title={title} description={description} />

      {buttonTitle && (
        <NotificationButton
          size="large"
          variant="light-blue-lowercase"
          onClick={handleClick}
          data-button="notificationButton"
        >
          {buttonTitle}
        </NotificationButton>
      )}
    </NotificationBlockWrapper>
  );

export default NotificationBlock;
