import Button from '@anm/components/buttons/Button';
import Image from '@anm/components/image/Image';
import { flexCenter } from '@anm/styles/helpers';
import styled from 'styled-components';

export const NotificationIcon = styled(Image)`
  margin-bottom: 30px;
`;

export const NotificationButton = styled(Button)`
  margin-top: 34px;
`;

const NotificationBlockWrapper = styled.div`
  ${flexCenter};
  pointer-events: all;
  flex-direction: column;
  text-align: center;
  padding: 45px 10px 40px;
  max-width: 440px;
  
  div {
    overflow-wrap: anywhere;
  }
`;

export default NotificationBlockWrapper;
