import { toObject } from '@anm/helpers/parseParams';
import { utmPropNames } from '@anm/helpers/utm/pickUTMParams';
import { ExtractTypeFromArray } from 'helpers';
import pick from 'lodash/fp/pick';

import routes from '../../../routes';

const { Router } = routes;

export type AuthRouteParamsName = 'name' | 'logo' | 'entry' | 'redirectUrl' | ExtractTypeFromArray<typeof utmPropNames>;
export type AuthRouteParams = { [key in AuthRouteParamsName]: string };
export const allowedAuthParams: AuthRouteParamsName[] = ['name', 'logo', 'entry', 'redirectUrl', ...utmPropNames];

export const getAuthParamsObject = () => pick(allowedAuthParams)(toObject<AuthRouteParams>(location.href, true));

export const isEditorEmbed = () => /editor\/embed/.test(Router.router!.route);
export const isEditorSignup = () => /editor\/signup/.test(Router.router!.route);
