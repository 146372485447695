import pick from 'lodash/fp/pick';
import { connect } from 'react-redux';

import { authActions } from '../store/modules/auth';
import { ApplicationState } from '../store/types';

export type WithAuth = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = ({ auth }: ApplicationState) => ({
  auth
});

const mapDispatchToProps = pick<typeof authActions, keyof typeof authActions>([
  'login',
  'signup',
  'logout',
  'loginOAuth',
  'loginWithToken',
  'logoutWithRedirect'
])(authActions);

export default connect(mapStateToProps, mapDispatchToProps);
