import Button, { ButtonProps } from '@anm/components/buttons/Button';
import getPageNameFromUrl from '@anm/helpers/getPageNameFromUrl';
import toCamelCase from '@anm/helpers/string/toCamelCase';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import routes from '../../../../routes';
import { appMetaSelectors } from '../../../../store/modules/appMeta';

import { loginUrls } from './data';
import { getLoginLink, getLoginParams } from './helpers';

export { loginUrls };
const { Link } = routes;

const LoginButton: FC<ButtonProps> = ({ ...buttonProps }) => {
  const path = useSelector(appMetaSelectors.selectAppMetaPath);
  const product = useSelector(appMetaSelectors.selectAppMetaProduct);
  const pageName = getPageNameFromUrl(path);
  const dataAttr = toCamelCase(`login button on ${pageName} page`);

  return (
    <Link route={getLoginLink(product)} params={getLoginParams()}>
      <a>
        <Button {...buttonProps} data-button={dataAttr}>
          Sign In
        </Button>
      </a>
    </Link>
  );
};

export default LoginButton;
