import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  activate,
  add,
  deactivate,
  remove
} from '../store/modules/notification';
import { ApplicationState } from '../store/types';

export type WithNotificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = ({ notification }: ApplicationState) => ({
  notification: {
    ...notification,
    count: notification.notifications.length
  }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  notificationActions: {
    ...bindActionCreators(
      {
        activate,
        add,
        deactivate,
        remove
      },
      dispatch
    ),
    dispatch
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
