import isValuesEmpty from '@anm/helpers/is/isValuesEmpty';
import { RouteParams } from 'next-routes';
import { AnimatronProduct } from 'types/global';

import { getAuthParamsObject, isEditorEmbed, isEditorSignup } from '../helpers';

import { blackList } from './data';

const isUrlAllowedToComeBack = () => !blackList.some(url => new RegExp(`^/${url}`).test(location.pathname));

export const getLoginLink = (productName: AnimatronProduct) => {
  switch (true) {
    case isEditorEmbed():
      return 'login-embed';
    case isEditorSignup():
      return 'editor-login';
    case productName === 'STUDIO':
      return 'studio-login';
    default:
      return 'login';
  }
};

export const getLoginParams = () => {
  const authParamsObject = getAuthParamsObject();
  const { redirectUrl } = authParamsObject;
  const params = {
    ...authParamsObject,
    ...((isUrlAllowedToComeBack() || redirectUrl) && {
      redirectUrl: redirectUrl || location.href
    })
  };

  const notEmptyParams = isValuesEmpty(params) ? undefined : (params as RouteParams);

  return notEmptyParams;
};
