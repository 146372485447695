import CardModal from '@anm/components/modals/CardModal';
import { FC } from 'react';
import {
  branch,
  compose,
  mapProps,
  renderComponent,
  withHandlers
} from 'recompose';
import { Action, Dispatch } from 'redux';

import withNotification, {
  WithNotificationProps
} from '../../../HOCs/withNotification';
import callActions from '../../../helpers/callActions';
import { Notification } from '../../../store/modules/notification';
import NotificationBlock from '../NotificationBlock';

type HOCs = WithNotificationProps & MappedProps & Handlers;
type Handlers = {
  handleClick: () => void;
};
type MappedProps = {
  close: () => void;
  dispatch: Dispatch;
  notification: Notification | null;
};
export type NotificationModalProps = {};

const NotificationModal: FC<NotificationModalProps & HOCs> = ({
  close,
  handleClick,
  notification: { icon, title, description, button, onClose }
}) => (
  <CardModal onModalClose={callActions([close, onClose])}>
    <NotificationBlock
      icon={icon}
      title={title}
      description={description}
      buttonTitle={button && button.title}
      handleClick={handleClick}
    />
  </CardModal>
);

const isReduxAction = (action: Action | Function): action is Action =>
  typeof action !== 'function';

export default compose(
  withNotification,
  mapProps<MappedProps, WithNotificationProps>(
    ({ notification, notificationActions, ...other }) => ({
      notification: notification.active,
      close: notificationActions.deactivate,
      dispatch: notificationActions.dispatch,
      ...other
    })
  ),
  withHandlers<WithNotificationProps & MappedProps, Handlers>({
    handleClick: ({ dispatch, notification: { button } }) => () =>
      button &&
      (isReduxAction(button.action) ? dispatch(button.action) : button.action())
  }),
  branch<MappedProps>(
    ({ notification }) => !notification,
    renderComponent(() => null)
  )
)(NotificationModal);
